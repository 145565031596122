$mainTextColor: #fff;
$secondaryTextColor: #8a8a8a;
$secondaryActionColor: #889ce7;
$mainActionColor: #fd6262;
$mainBackgroundColor: #141416;
$secondaryBackgroundColor: #1b1c1e;
$borderColor: #474747;
$borderRadius: 7px;
$basicBoxShadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.2);

$mediumScreen: 1050px;
$smallScreen : 900px;
$mobileScreen: 450px;

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

* {
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html,
body,
#root {
	height: 100%;
}

body {
	font-family: 'Inter';
}

html {
	box-sizing: border-box;
	font-size: 62.5%; //1rem = 10px
}

.app {
	position: relative;
}

.content {
	padding-top: 8rem;
}

.mainButton {
	display: inline-block;
	padding: 1.8rem 3.4rem;
	background-color: $mainActionColor;
	border-radius: $borderRadius;
	color: $mainBackgroundColor;
	border: 1px solid $mainActionColor;
	outline: none;
	cursor: pointer;
	font-size: 1.6rem;
	transition: all 0.4s;
	text-decoration: none;

	&:hover {
		border: 1px solid $mainTextColor;
		background-color: transparent;
		color: $mainTextColor;
	}
}

.mainHeader {
	font-size: 3rem;
	font-weight: 400;
	color: $mainTextColor;
}

.mainText {
	font-size: 1.6rem;
	color: $secondaryTextColor;
}

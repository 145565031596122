.services {
    background-color: $mainBackgroundColor;
    &__header {
        min-height: 40vh;
        width: 100%;
        background-image: url('../../public/images/services_background.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        color: $mainTextColor;
        padding: 10rem 8rem 0 8rem;
        background-color: $mainBackgroundColor;

        @media (max-width: $smallScreen) {
            padding: 10rem 4rem 0 4rem;
        }

        & > h1 {
            font-size: 6rem;
            max-width: 85rem;
            font-weight: 400;

            @media (max-width: $smallScreen) {
                font-size: 6rem;
            }

            @media (max-width: $mobileScreen) {
                font-size: 4.5rem;
            }
        }

        & > h2 {
            font-size: 3rem;
            font-weight: 300;
            margin-top: 4rem;
            margin-bottom: 6rem;

            @media (max-width: $smallScreen) {
                font-size: 2.6rem;
            }

            @media (max-width: $mobileScreen) {
                font-size: 2.2rem;
            }
        }
    }

    &__main {
        background-color: $mainBackgroundColor;
        min-height: 60vh;
        width: 100%;
        padding: 0 8rem 10rem 8rem;

        @media (max-width: $smallScreen) {
            padding: 0 4rem 10rem 4rem;
        }

        &-line {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            border: 1px solid $borderColor;
            min-height: 23rem;

            @media (max-width: $smallScreen) {
                flex-direction: column;
            }

            &:not(:first-child) {
                border-top: none;
            }

            & > div:first-child {
                width: 20%;
                border-right: 1px solid $borderColor;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: $smallScreen) {
                    width: 100%;
                    border-bottom: 1px solid $borderColor;
                    padding: 2rem;
                }
            }

            &> div:last-child {
                width: 80%;
                padding-top: 6rem;
                padding-left: 10rem;

                @media (max-width: $smallScreen) {
                    width: 100%;
                    padding: 2rem;
                    height: 100%;
                }

                & > h4 {
                    color: $mainTextColor;
                    font-size: 1.8rem;
                    font-weight: 600;
                }

                & > p {
                    color: $secondaryTextColor;
                    font-size: 1.6rem;
                    margin-top: 2rem;
                    max-width: 70%;
                }
            }
        }

        &-iconBox {
            height: 10rem;
            width: 10rem;
            border-radius: $borderRadius;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;
            padding: 1rem;

            & > img {
                height: 5rem;
            }

            & > div {
                width: 5rem;
                height: 4px;
                background-color: #000;
                border-radius: 1rem;
            }
        }

        &-results {
            width: 100%;
            height: 48rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid $borderColor;
            border-bottom: 1px solid $borderColor;
            border-left: 1px solid $borderColor;

            @media (max-width: $smallScreen) {
                flex-direction: column;
                height: 100%;
            }

            & > div:first-child {
                height: 100%;
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: url('../../public/images/services_results.jpg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                border-right: 1px solid $borderColor;
                color: $mainTextColor;
                background-color: $mainBackgroundColor;

                @media (max-width: $smallScreen) {
                    width: 100%;
                    min-height: 20rem;
                }

                & > h3 {
                    width: 50%;
                    text-align: center;

                    @media (max-width: $mobileScreen) {
                        font-size: 2rem;
                    }
                }
            }

            & > div:last-child {
                height: 100%;
                width: 60%;
                padding: 10rem;
                font-size: 1.6rem;
                color: $secondaryTextColor;

                @media (max-width: 1300px) {
                    padding: 5rem;
                }

                @media (max-width: $smallScreen) {
                    padding: 2rem;
                    width: 100%;
                    min-height: 50rem;
                }
            }
        }
    }
}

.boxPrimaryBg {
    background-color: $mainActionColor;
}

.boxSecondaryBg {
    background-color: $secondaryActionColor;
}
.products {
    &__header {
        min-height: 40vh;
        width: 100%;
        color: $mainTextColor;
        background-color: $mainBackgroundColor;
        padding: 10rem 8rem 0 8rem;
        background-image: url('../../public/images/home_numbers.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @media (max-width: $smallScreen) {
            padding: 10rem 4rem 0 4rem;
            min-height: 60vh;
        }

        & > h1 {
            font-size: 6rem;
            max-width: 85rem;
            font-weight: 400;

            @media (max-width: $smallScreen) {
                font-size: 6rem;
            }

            @media (max-width: $mobileScreen) {
                font-size: 4.5rem;
            }
        }
    }

    &__main {
        height: 60vh;
        width: 100%;
        padding: 0 8rem;
        background-color: $mainBackgroundColor;
        display: flex;
        justify-content: space-around;

        @media (max-width: $smallScreen) {
            padding: 0 4rem;
        }

        & > .product {
            margin-top: -10rem;
        }
    }
}

.product {
    width: 40rem;
    height: 50rem;
    box-shadow: $basicBoxShadow;
    border-radius: $borderRadius;
    transition: all .4s;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 2rem 4rem;
    background-color: $mainBackgroundColor;

    @media (max-width: $mobileScreen) {
        padding: 1rem 2rem;
    }

    &:hover {
        transform: scale(1.05);
    }

    & > h4 {
        font-size: 3rem;
        color: $mainTextColor;
        font-weight: 600;
    }

    & > img {
        height: 8rem;
        cursor: pointer;
    }

    & > p {
        font-size: 1.6rem;
        color: $mainTextColor;
        margin-bottom: 2rem;
        text-align: center;
    }
}
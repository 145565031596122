.home {
    width: 100%;

    &__main {
        height: 100vh;
        width: 100%;
        background-image: url('../../public/images/home.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
        background-color: $mainBackgroundColor;
        padding: 0 8rem;
        padding-bottom: 2rem;

        @media (max-width: $smallScreen) {
            padding: 0 4rem;
            padding-bottom: 1rem;
        }

        &-box {
            color: $mainTextColor;
            padding-top: 20rem;

        & > h1 {
            font-size: 8rem;
            max-width: 85rem;
            font-weight: 400;

            @media (max-width: $smallScreen) {
                font-size: 6rem;
            }

            @media (max-width: $mobileScreen) {
                font-size: 4.5rem;
            }
        }

        & > h2 {
            font-size: 3rem;
            font-weight: 300;
            margin-top: 4rem;
            margin-bottom: 6rem;

            @media (max-width: $smallScreen) {
                font-size: 2.6rem;
            }

            @media (max-width: $mobileScreen) {
                font-size: 2.2rem;
            }
        }
        }
    }

    &__ourService {
        padding: 0 8rem;
        min-height: 60vh;
        width: 100%;
        background-color: $mainBackgroundColor;

        @media (max-width: $smallScreen) {
            padding: 0 4rem;
        }

        &-box {
            width: 100%;
            height: 50vh;
            transform: translateY(-10rem);
            border: 1px solid $borderColor;
            display: flex;
            align-items: center;
            background-color: $mainBackgroundColor;

            @media (max-width: $mediumScreen) {
                flex-direction: column !important;
                height: auto;
            }

            @media (max-width: $smallScreen) {
                transform: none;
            }
        }

        &-header {
            border-right: 1px solid $borderColor;
            width: 40%;
            height: 100%;
            padding-top: 25rem;
            padding-left: 10rem;
            background-image: url('../../public/images/home_ourService.jpg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-color: $mainBackgroundColor;

            @media (max-width: $mediumScreen) {
                width: 100%;
                padding: 1rem;
                height: 30rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-top: 1px solid $borderColor;
                border-right: none;
            }

            & > h4 {
                margin-top: 2.6rem;
                font-size: 1.6rem;
                color: $secondaryTextColor;
            }
        }

        &-content {
            width: 60%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $mainBackgroundColor;

            & > div {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & > div > .homeCardBox:last-child {
                margin-left: 2rem;
            }
        }
    }

    &__about {
        padding: 0 8rem;
        min-height: 60vh;
        width: 100%;
        background-color: $mainBackgroundColor;

        @media (max-width: $mediumScreen) {
            padding-top: 20rem;
        }

        @media (max-width: $smallScreen) {
            padding: 0 4rem;
        }

        &-box {
            width: 100%;
            height: 50vh;
            background-color: $mainBackgroundColor;
            border: 1px solid $borderColor;
            display: flex;
            align-items: center;

            @media (max-width: $mediumScreen) {
                border-right: none;
                border-left: none;
                border-bottom: none;
                flex-direction: column;
            }

            @media (max-width: $smallScreen) {
                height: 60vh;
            }
        }

        &-section {
            height: 100%;
            width: 50%;

            @media (max-width: $mediumScreen) {
                width: 100%;
                padding: 1rem;
            }

            &:first-child {
                border-right: 1px solid $borderColor;
                background-image: url('../../public/images/home_about.jpg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                background-color: $mainBackgroundColor;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: $mediumScreen) {
                    border-radius: none;
                    border-left: 1px solid $borderColor;
                    min-height: 20rem;
                }
            }

            &:last-child {
                padding: 10rem;

                @media (max-width: $mediumScreen) {
                    padding: 2rem;
                    border: 1px solid $borderColor;
                }

                & > p {
                    margin-bottom: 1rem;
                }
            }
        }
    }

    &__aboutNumbers {
        text-align: center;
        background-color: $mainBackgroundColor;

        @media (max-width: $mediumScreen) {
            padding-top: 10rem;
        }

        @media (max-width: $mobileScreen) {
            padding-top: 35rem;
            min-height: 100vh;
        }

        & > h3 {
            margin-bottom: 6rem;
        }

        &-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16rem;

            @media (max-width: $mediumScreen) {
                flex-direction: column;

                & > div {
                    margin-top: 2rem;
                }
            }
        }
    }

    &__emptySection {
        background-image: url('../../public/images/home_numbers.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: $mainBackgroundColor;
        height: 50rem;
        width: 100%;
        margin-top: -15rem;
    }

    &__products {
        padding: 0 8rem;
        background-color: $mainBackgroundColor;
        padding: 10rem;

        @media (max-width: $smallScreen) {
            padding: 0 4rem;
        }

        &-table {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $borderColor;

            & > div {
                width: 100%;
                height: 20rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $mainTextColor;
                padding: 1rem;

                &:not(:last-child) {
                    border-right: 1px solid $borderColor;
                }

                & > img {
                    height: 8rem;
                }
            }
        }

        &-main {
            display: flex;
            width: 100%;
            height: 50rem;
            border-right: 1px solid $borderColor;
            border-left: 1px solid $borderColor;
            border-bottom: 1px solid $borderColor;

            @media (max-width: $smallScreen) {
                flex-direction: column;
            }

            & > div {
                display: flex;
                justify-content: center;
                align-items: center;

                &:first-child {
                    background-image: url('../../public/images/home_ourService.jpg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    background-color: $mainBackgroundColor;
                    width: 60%;
                    border-right: 1px solid $borderColor;
                    padding: 2rem;

                    @media (max-width: $smallScreen) {
                        width: 100%;
                        height: 25rem;
                    }
                }

                &:last-child {
                    width: 40%;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-left: 10rem;
                    font-size: 1.6rem;
                    color: $secondaryTextColor;

                    @media (max-width: $smallScreen) {
                        width: 100%;
                        height: 100%;
                    }

                    & > p {
                        width: 60%;
                        line-height: 2.8rem;
                        margin-bottom: 2rem;
                    }
                }
            }
        }

        &-empty {
            display: inherit;
            @media (max-width: $smallScreen) {
                display: none !important;
            }
        }

        &-logo {
            @media (max-width: $smallScreen) {
                height: 5rem !important;
            }
        }
    }
}

.homeCardBox {
    &:first-child {
        transform: translateY(-12rem);

        @media (max-width: $mediumScreen) {
            transform: translateY(0rem);
        }
    }
    &:last-child {
        transform: translateY(-4rem);
    }
}

.homeCard {
    margin-bottom: 2rem;
    height: 30rem;
    width: 24rem;
    background-color: $secondaryBackgroundColor;
    border-radius: 1rem;
    box-shadow: $basicBoxShadow;
    padding: 2rem;

    @media (max-width: $mediumScreen) {
        width: 18rem;
    }

    @media (max-width: $mobileScreen) {
        width: 15rem;
        height: 24rem;
    }

    & > img {
        height: 6rem;
    }

    & > h4 {
        font-size: 1.8rem;
        color: $secondaryActionColor;
        margin-top: 5rem;
        margin-bottom: 9rem;

        @media (max-width: $mediumScreen) {
            margin-top: 4rem;
            margin-bottom: 5rem;
        }

        @media (max-width: $mobileScreen) {
            margin-top: 2rem;
            margin-bottom: 3rem;
        }
    }

    &__line {
        height: 5px;
        width: 80%;
        background-color: #3F4349;
        border-radius: 10px;
        margin: 0 auto;
    }
}

.numbersItem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 12%;

    @media (max-width: $mediumScreen) {
        min-width: 12rem;
    }

    & > h4 {
        font-size: 5rem;
        color: $mainActionColor;
        font-weight: 400;
    }

    & > p {
        color: $secondaryTextColor;
        font-size: 1.6rem;
        min-height: 4rem;
        margin: 2rem 0;
    }

    &__line {
        width: 100%;
        height: 5px;
        border-radius: 5px;
        background-color: #3F4349;
    }
}

.vision {
	width: 100%;
	background-color: $mainBackgroundColor;
	padding-bottom: 10rem;

	&__header {
		color: $mainTextColor;
		padding: 10rem 8rem 0 8rem;
		min-height: 50vh;
		background-image: url('../../public/images/services_background.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		@media (max-width: $mediumScreen) {
			padding: 10rem 4rem 0 4rem;
		}

		& > h1 {
			font-size: 6rem;
			font-weight: 400;
			margin-bottom: 1rem;
		}

		& > h2 {
			font-size: 2rem;
			font-weight: 300;
			width: 55%;

			@media (max-width: 1200px) {
				width: 70%;
			}

			@media (max-width: $mediumScreen) {
				width: 80%;
			}
		}
	}

	&__main {
		padding: 0 8rem;

		@media (max-width: $mediumScreen) {
			padding: 0 4rem;
		}

		&-line {
			min-height: 60rem;
			border: 1px solid $borderColor;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;

			@media (max-width: $mediumScreen) {
				flex-direction: column;
			}

			&:first-child {
				margin-top: -7rem;

				@media (max-width: $mediumScreen) {
					margin-top: 2rem;
				}
			}

			& > div:first-child {
				border-right: 1px solid $borderColor;
				width: 40%;
				color: $mainTextColor;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				background-image: url('../../public/images/vision_story.jpg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				min-height: 60rem;

				@media (max-width: $mediumScreen) {
					width: 100%;
				}
			}

			& > div:last-child {
				border-right: 1px solid $borderColor;
				width: 60%;
				color: $secondaryTextColor;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				font-size: 1.6rem;
				line-height: 2.8rem;
				background-color: $mainBackgroundColor;
				padding: 2rem;
				min-height: 60rem;

				@media (max-width: $mediumScreen) {
					width: 100%;
				}

				& > p {
					width: 60%;

					@media (max-width: $smallScreen) {
						width: 100%;
					}
				}
			}

			&:nth-of-type(even) {
				flex-direction: row-reverse;

				@media (max-width: $mediumScreen) {
					flex-direction: column;
				}

				& > div:first-child {
					border-right: none;
					border-left: 1px solid $borderColor;
					background-image: url('../../public/images/vision_leadership.jpg');
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}

				& > div:last-child {
					border-right: 1px solid $borderColor;
					border-left: none;
				}
			}
		}
	}
}

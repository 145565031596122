.contactForm {
    background-color: $mainBackgroundColor;
    padding-bottom: 10rem;

    &__main {
        min-height: 50vh;
        width: 100%;
        padding: 0 8rem;

        @media (max-width: $mediumScreen) {
            padding: 0 4rem;
            min-height: 65vh;
        }

        @media (max-width: $mobileScreen) {
            padding: 2rem 4rem;
        }

        &-box {
            height: 65rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $borderColor;
            margin-top: -10rem;

            @media (max-width: $smallScreen) {
                flex-direction: column;
                min-height: 85rem;
            }

            @media (max-width: $mobileScreen) {
                margin-top: 10rem;
            }

            & > div:first-child {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40%;
                height: 100%;
                border-right: 1px solid $borderColor;
                background-color: $mainBackgroundColor;

                @media (max-width: $smallScreen) {
                    width: 100%;
                    border-bottom: 1px solid $borderColor;
                    border-right: none;
                }

                & > p {
                    width: 50%;
                    color: $secondaryTextColor;
                    line-height: 2.8rem;
                    font-size: 1.6rem;
                }
            }

            & > div:last-child {
                width: 60%;
                height: 100%;
                background-color: $mainBackgroundColor;

                @media (max-width: $smallScreen) {
                    width: 100%;
                }
            }
        }
    }
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;

    &__error {
        color: red;
        margin-top: 1rem;
        font-size: 1.6rem;
        padding: 0 10rem;
        text-align: center;
    }

    &__message {
        color: green;
        margin-top: 1rem;
        font-size: 1.6rem;
        padding: 0 10rem;
        text-align: center;
    }

    &__line {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        @media (max-width: 1300px) {
            flex-direction: column;

            & > .inputWrapper:last-child {
                margin-top: 1rem !important;
            }
        }

        & > button {
            margin: 1rem 0;
        }

        & > div {
            margin: 0 1rem;
        }
    }
}
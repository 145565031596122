.inputError {
    border-color: red !important;
}

.inputWrapper {
    @media (max-width: 1000px) {
        margin: .2rem;
    }

    & > div {
        text-align: left;
        font-size: 1.6rem;
        color: $secondaryTextColor;
        margin-bottom: 1rem;
        margin-left: 1rem;

        @media (max-width: 1000px) {
            font-size: 1.4rem;
        }
    }

    & > input {
        height: 4rem;
        width: 30rem;
        border-radius: 1.2rem;
        outline: none;
        border: 1px solid $secondaryTextColor;
        padding: 1rem;
        font-size: 1.6rem;
        color: $secondaryTextColor;
        background-color: transparent;

        @media (max-width: 1000px) {
            height: 3rem;
            width: 20rem;
        }

        &:focus {
            border: 2px solid $secondaryTextColor;
        }
    }

    & > .label {
        & > span {
            color: red;
            margin-left: 1rem;
        }
    }
}
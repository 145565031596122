.footer {
    z-index: 2;
    padding: 0 8rem 2rem 8rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $mainBackgroundColor;
    height: 20rem;
    color: $secondaryTextColor;
    font-size: 1.6rem;

    @media (max-width: $smallScreen) {
        padding: 2rem 4rem;
    }

    &__contact {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        & > a {
            cursor: pointer;
            text-decoration: none;
            margin-top: 2rem;
            color: $secondaryTextColor;
        }
    }

    &__social {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        &-media {
            margin-top: 1rem;

            & > img {
                margin-right: .5rem;
            }
        }
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
            margin-right: 1rem;
            height: 4rem;
        }

        & > h4 {
            font-size: 2.2rem;
            font-weight: 400;
            color: $mainTextColor;
        }
    }
}

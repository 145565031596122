.hamburgerMenu {
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);

    &__icon {
        font-size: 6rem;
        display: none;
        color: $mainTextColor;
        cursor: pointer;

        @media (max-width: $smallScreen) {
            display: block;
        }
    }

    & > nav {
        padding: 5rem 0 0 5rem;
        display: flex;
        flex-direction: column;

        & > a {
            font-size: 3rem;
            color: #FFF;
            text-decoration: none;
            margin-bottom: 2rem;
            z-index: 6;
            cursor: pointer;
        }
    }

    &__close {
        font-size: 4rem;
        z-index: 6;
        color: #FFF;
        position: absolute;
        top: 5rem;
        right: 5rem;
        font-weight: 300;
        cursor: pointer;
    }

    &__langs {
        z-index: 5;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        left: 5rem;
        bottom: 5rem;

        & > div {
            color: #FFF;
            font-size: 3rem;
            cursor: pointer;
        }
    }

    &__divider {
        background-color: #FFF;
        width: 2px;
        height: 3rem;
        margin: 0 1rem;
    }
}
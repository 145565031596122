.header {
	position: fixed;
	z-index: 2;
	padding: 2rem 8rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background-color: $mainBackgroundColor;

	@media (max-width: $smallScreen) {
		padding: 1rem 4rem;
	}

	&__logo {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		text-decoration: none;

		& > div {
			margin-left: 1rem;
			color: $mainTextColor;
			font-size: 2.4rem;
		}

		& > img {
			position: relative;
			height: 4rem;
		}
	}

	&__link {
		margin-left: 2rem;
		font-size: 1.6rem;
		color: $mainTextColor;
		text-decoration: none;
		transition: all .2s;

		&:hover {
			color: $mainActionColor;
		}

		&-active {
			color: $mainActionColor;
		}
	}

	&__box {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		@media (max-width: 900px) {
            display: none;
        }
	}

	&__lang {
		display: flex;
		align-items: center;
		color: $mainTextColor;
		font-size: 1.6rem;
		margin-left: 5rem;

		&-divider {
			height: 2.4rem;
			width: 2px;
			margin: 0 1.2rem;
			background-color: $mainTextColor;
		}

		&-item {
			cursor: pointer;
			transition: all .2s;

			&:hover {
				color: $mainActionColor;
			}
		}

		&-active {
			color: $mainActionColor !important;
		}
	}
}

.contact {
	background-color: $mainBackgroundColor;

	&__header {
		min-height: 55vh;
		margin: 0 auto;
		padding-top: 12rem;
		max-width: 62rem;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		padding: 0 4rem;
		padding-bottom: 4rem;
		clear: both;

		& > h1 {
			font-size: 6rem;
			font-weight: 400;
			color: $mainTextColor;
			margin-bottom: 3rem;

			@media (max-width: 1400px) {
				padding-top: 10rem;
			}
		}

		& > p {
			font-size: 1.6rem;
			color: $secondaryTextColor;
			line-height: 2.8rem;
		}
	}

	&__main {
		height: 50vh;
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 0 10rem;

		@media (max-width: 1400px) {
			height: 100%;
		}

		@media (max-width: 1400px) {
			flex-direction: column;
		}

		& > .participant {
			margin-top: -22rem;

			@media (max-width: 1400px) {
				margin-top: 0;
			}
		}
	}
}

.participant {
	width: 20%;
	min-height: 60rem;
	box-shadow: $basicBoxShadow;
	border-radius: $borderRadius;
	transition: all 0.4s;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 4rem 4rem;
	background-color: $mainBackgroundColor;
	padding-top: 8rem;

	&__contact {
		color: #FFF;
		font-size: 1.4rem;
		width: 100%;
		margin-top: 5rem;

		& > a {
			color: #FFF;
			text-decoration: none;
		}
	}

	@media (max-width: $mobileScreen) {
        padding: 2rem;
    }

	@media (max-width: 1400px) {
		min-width: 40rem;
		margin-bottom: 5rem;
	}

	@media (max-width: $mobileScreen) {
		min-width: 30rem;
		width: 30rem;
	}

	& > img {
		border-radius: 100rem;
		height: 14rem;
	}

	& > h3 {
		color: $mainTextColor;
		font-size: 2.6rem;
		font-weight: 500;
		margin-top: 4rem;
	}

	& > h4 {
		color: $mainTextColor;
		font-size: 2rem;
		font-weight: 300;
		margin-top: 0.6rem;
	}

	& > p {
		margin-top: 5rem;
		font-size: 1.6rem;
		color: $secondaryTextColor;
		min-height: 8rem;
	}
}

.participantIcon {
	border: 2px solid #fff;
	padding: 1.5rem;
}
